<template>
  <div id="dashboard" class="dashboard">
    <v-system-bar height="auto" v-show="systemBarShow">
      <v-container fluid>
        <v-alert
          border="left"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
          dismissible
        >
          Aliquam eu nunc. Fusce commodo aliquam arcu. In consectetuer turpis ut
          velit. Nulla facilisi.. Morbi mollis tellus ac sapien. Fusce vel dui.
          Praesent ut ligula non mi varius sagittis. Vivamus consectetuer
          hendrerit lacus. Suspendisse enim turpis, dictum sed, iaculis a,
          condimentum nec, nisi.
        </v-alert>
      </v-container>
    </v-system-bar>

    <v-app-bar dense app>
      <v-app-bar-nav-icon @click="drawer ? (drawer = false) : (drawer = true)">
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer">
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="selectedDrawerItem" color="primary">
          <v-list-item v-for="(item, i) in drawerItems" :key="i">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group prepend-icon="mdi-spider" :value="true" no-action>
          <template v-slot:activator>
            <v-list-item-title>异常反馈</v-list-item-title>
          </template>

          <v-list-item link @click="$router.push('/feedback/list')">
            <v-list-item-title> 异常列表 </v-list-item-title>
          </v-list-item>

          <v-list-item link @click="$router.push('/feedback/submit')">
            <v-list-item-title> 上报BUG </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-container fluid>
      <router-view />
    </v-container>
  </div>
</template>

<style scoped>
</style>

<script>
import Dashboard from "./dashboard";
export default Dashboard;
</script>
