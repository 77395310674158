import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {

  }
})
export default class Dashboard extends Vue {
  drawer = true;
  systemBarShow = false;
  selectedDrawerItem = 0;
  drawerItems = [
    { text: '主页', icon: 'mdi-home' },
    //{ text: '无限团规则书', icon: 'mdi-database-edit' },
    { text: '车卡(未实现)', icon: 'mdi-card-account-details' },
    { text: 'GamesEvent', icon: 'mdi-gamepad-square' },
    { text: '更新历史', icon: 'mdi-clipboard-text-clock-outline' },
    { text: '塔罗牌占卜', icon: 'mdi-cards-playing-diamond-multiple' },
    { text: '系统配置', icon: 'mdi-application-cog-outline' },
    { text: 'RobotToken', icon: 'mdi-robot' },
    { text: '书籍管理', icon: 'mdi-database-edit' },
    { text: 'FF14幻卡模拟器', icon: 'mdi-card' }
  ];


  @Watch('selectedDrawerItem')
  public skipSubPage(index: number) {
    switch (index) {
      case 0:
        this.$router.push('/dashboard/home');
        break;
      case 1:
        this.$router.push('/navigation/create/infinite');
        break;
      case 2:
        this.$router.push('/games/event');
        break;
      case 3:
        this.$router.push('/history');
        break;
      case 4:
        this.$router.push('/tarot');
        break;
      case 5:
        this.$router.push('/control');
        break;
      case 6:
        this.$router.push('/robot/token');
        break;
      case 7:
        this.$router.push('/books');
        break;
      case 8:
        this.$router.push('/tools/ninecard')
        break;
    }
  }



}


